<template>
  <q-list v-if="app.letter?.coverItems?.length" padding>
    <work-item
      v-for="(item, coverIndex) in app.letter.coverItems"
      :data="item"
      :work-index="coverIndex"
      :start-index="getStartIndex(coverIndex)"
      :key="item._key"
    />
  </q-list>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";
import WorkItem from "./WorkItem.vue";

defineOptions({ name: "WorkItems" });

const appStore = useAppStore();
const { app } = storeToRefs(appStore);

const getStartIndex = (coverIndex) => {
  return app.value.letter.coverItems
    .slice(0, coverIndex)
    .reduce((sum, item) => sum + (item.projects?.length || 0), 0);
};
</script>
