<template>
  <q-layout view="lhh LpR lff" container>
    <q-header class="text-grey transparent">
      <q-tabs
        v-model="tab"
        active-color="dark"
        indicator-color="dark"
        narrow-indicator
        no-caps
      >
        <q-tab name="work" label="Work" />
        <q-tab name="cv" label="Resume" />
        <q-tab name="contact" label="Contact" />
      </q-tabs>
    </q-header>
    <q-page-container>
      <q-page>
        <q-scroll-area class="absolute-full mask-overflow-tb-drawer">
          <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="work">
              <work-items />
            </q-tab-panel>
            <q-tab-panel name="cv">
              <cv-items />
            </q-tab-panel>
            <q-tab-panel name="contact">
              <q-list padding>
                <q-item-label header>chris@quezada.work</q-item-label>
              </q-list>
            </q-tab-panel>
          </q-tab-panels>
        </q-scroll-area>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { ref } from "vue";
import CvItems from "../cv/CvItems.vue";
import WorkItems from "../work/WorkItems.vue";

defineOptions({ name: "DrawerTabs" });

const tab = ref("work");
</script>
