<template>
  <q-layout view="lHr LpR fFf">
    <q-header class="bg-white">
      <q-toolbar>
        <q-toolbar-title class="text-dark text-subtitle2">
          Chris Quezada
        </q-toolbar-title>
        <q-btn
          color="dark"
          flat
          dense
          round
          icon="menu"
          aria-label="CV"
          @click="toggleRightDrawer"
        />
      </q-toolbar>
    </q-header>
    <q-drawer v-model="rightDrawerOpen" bordered side="right" show-if-above>
      <drawer-tabs />
    </q-drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { ref } from "vue";
import DrawerTabs from "src/components/drawer/DrawerTabs.vue";

defineOptions({ name: "MainLayout" });

const rightDrawerOpen = ref(false);

const toggleRightDrawer = () => {
  rightDrawerOpen.value = !rightDrawerOpen.value;
};
</script>
